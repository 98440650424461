var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("section", { staticClass: "section" }, [
      _vm.elitepage.about_visible && _vm.producer
        ? _c(
            "div",
            { staticClass: "container container--sm h-textCenter h-mb-80" },
            [
              _c("h1", { staticClass: "h1" }, [_vm._v("About")]),
              _c("img", {
                staticClass: "producerLogo",
                attrs: { src: _vm.producer.image_logo_url },
              }),
              _c("h3", { staticClass: "h3" }, [_vm._v("Biography")]),
              _c("hr", { staticClass: "dividerCustom" }),
              _c("p", { staticClass: "p h-mb-10" }, [
                _vm._v(_vm._s(_vm.producer.bio)),
              ]),
              _vm.producer.about_epk_url
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn--secondary",
                      attrs: {
                        href: _vm.producer.about_epk_url,
                        target: "_blank",
                      },
                    },
                    [_vm._v("Download EPK")]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }